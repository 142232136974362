export const ESTIMATE_ITEM_STATUS_APPROVED = "APPROVED";
export const ESTIMATE_ITEM_STATUS_DENIED = "DENIED";
export const ESTIMATE_ITEM_STATUS_REVIEWED = "REVIEWED";

export const ESTIMATE_ITEM_CONDITION_TO_REPLACE = "TO_REPLACE";
export const ESTIMATE_ITEM_CONDITION_TO_REPAIR = "TO_REPAIR";

export const estimateItemStatusesOption = {
  ESTIMATE_ITEM_STATUS_APPROVED,
  ESTIMATE_ITEM_STATUS_DENIED,
  ESTIMATE_ITEM_STATUS_REVIEWED,
};

export const estimateItemConditionsOption = {
  ESTIMATE_ITEM_CONDITION_TO_REPLACE,
  ESTIMATE_ITEM_CONDITION_TO_REPAIR,
};

export const estimateItemConditionsOptionsDisplay = {
  [ESTIMATE_ITEM_CONDITION_TO_REPLACE]: {
    iconName: "fa-times-circle",
    color: "red",
    value: ESTIMATE_ITEM_CONDITION_TO_REPLACE,
  },
  [ESTIMATE_ITEM_CONDITION_TO_REPAIR]: {
    iconName: "fa-exclamation-triangle",
    color: "orange",
    value: ESTIMATE_ITEM_CONDITION_TO_REPAIR,
  },
};
