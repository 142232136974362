export const REPAIR_STATUS_DRAFT = "DRAFT";
export const REPAIR_STATUS_APPROVED = "APPROVED";
export const REPAIR_STATUS_ASSIGNED = "ASSIGNED";
export const REPAIR_STATUS_PENDING = "PENDING";
export const REPAIR_STATUS_IN_PROGRESS = "IN_PROGRESS";
export const REPAIR_STATUS_VALIDATED = "VALIDATED";
export const REPAIR_STATUS_COMPLETED = "COMPLETED";
export const REPAIR_STATUS_CANCELED = "CANCELED";

export const repairStatusesOption = [
  REPAIR_STATUS_DRAFT,
  REPAIR_STATUS_PENDING,
  REPAIR_STATUS_IN_PROGRESS,
  REPAIR_STATUS_VALIDATED,
  REPAIR_STATUS_COMPLETED,
  REPAIR_STATUS_CANCELED,
  REPAIR_STATUS_ASSIGNED,
];
