<template>
  <div>
    <span v-for="(user, idx) in users" :key="user.id">
      <user :user="user" />
      {{ users.length - 1 > idx ? "," : " " }}<br />
    </span>
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";
import User from "./User.vue";

export default {
  name: "users",

  components: { User },

  mixins: [formMixin],

  props: {
    users: {
      type: Array,
      default: () => [],
      description: "Users",
    },
  },

  data() {
    return {};
  },

  setup() {},

  created() {},

  methods: {},

  watch: {
    users(users) {},
  },
};
</script>
