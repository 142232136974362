<template>
  <div class="elite-tabs-wrapper-content">
    <div class="global-header">
      <h3>{{ estimate.code }} - {{ $t("COMMON.REPAIR") }}</h3>
      <div class="buttons">
        <button class="edit" @click="showRepair">
          <span> {{ $t("REPAIRS.SHOW_REPAIR") }} </span>
        </button>
      </div>
    </div>
    <repair-view-global
      class-name=""
      :repair="estimate.repair"
      :view-header="false"
      show-code
    />
  </div>
</template>

<script>
import RepairViewGlobal from "../../RepairManagement/partials/RepairViewGlobal.vue";

export default {
  name: "estimate-view-repair",

  components: { RepairViewGlobal },

  props: ["estimate"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {
    showRepair() {
      this.$router.push(this.$objectViewRoute(this.estimate.repair));
    },
  },

  mounted() {},

  watch: {},
};
</script>
