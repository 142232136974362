<template>
  <modal :show.sync="opened" :modal-classes="modalClasses">
    <template slot="header">
      <h3>{{ headerText ?? $t("COMMON.USER") }}</h3>
    </template>

    <p>{{ searchText ?? $t("COMMON.SEARCH") }}</p>

    <div class="search-form">
      <el-input
        :placeholder="searchText ?? $t('COMMON.SEARCH')"
        v-model="userSearch"
      >
        <i slot="prefix" class="fal fa-search"></i>
      </el-input>
    </div>

    <div class="others">
      <!-- <p>Autres</p> -->
      <div class="mechanic-checkbox">
        <el-radio-group v-model="userModel">
          <el-radio
            v-for="user in userFiltered"
            :key="user.id"
            :label="user.id"
            class="w-100 mb-3 text-dark user-select-none"
          >
            {{ $objectDenomination(user) }}
          </el-radio>
        </el-radio-group>
      </div>
    </div>

    <template slot="footer">
      <Button
        class="yes"
        @click.prevent="confirmAction"
        :disabled="userModel == userModelBackup"
        :class="{
          'opacity-5 cursor-not-allowed': userModel == userModelBackup,
        }"
      >
        <i class="fas fa-spinner fa-spin mr-1" v-if="loading"></i>
        {{ endText ?? $t("COMMON.END") }}
      </Button>
    </template>
  </modal>
</template>

<script>
import { Radio, RadioGroup } from "element-ui";

export default {
  name: "user-selector-modal",

  components: {
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
    headerText: {
      type: String | null | undefined,
      default: null,
    },
    endText: {
      type: String | null | undefined,
      default: null,
    },
    searchText: {
      type: String | null | undefined,
      default: null,
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    confirmAction: {
      type: Function,
      default: () => {},
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    user: {
      type: String,
      default: null,
      description: "User id",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterOrganizationNull: {
      type: Boolean,
      default: null,
      description: "Filter only null organizations",
    },
    filterReseller: {
      type: String,
      default: null,
      description: "Reseller id",
    },
    filterResellerNull: {
      type: Boolean,
      default: null,
      description: "Filter only null resellers",
    },
    filterPermission: {
      type: String,
      default: null,
      description: "Permission name",
    },
    modalClasses: {
      type: String,
      default: "modal-secondary mechanic-list",
    },
  },

  data() {
    const opened = this.open;
    const userModel = this.user ?? null;
    const userModelBackup = userModel;
    return {
      opened,
      userModel,
      userModelBackup,
      loadingList: false,
      userSearch: "",
      usersList: [],
    };
  },

  computed: {
    userFiltered() {
      return this.userSearch
        ? this.usersList.filter((user) =>
            this.$objectDenomination(user)
              .toLowerCase()
              .includes(this.userSearch.toLowerCase())
          )
        : this.usersList;
    },
  },

  created() {
    this.getUsers();
  },

  methods: {
    async getUsers(query = null) {
      try {
        this.loadingList = true;
        let params = {
          sort: "firstname",
          filter: {
            ...(query ? { search: query } : {}),
            // ...(users ? { ids: users.map((item) => item.id) } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.notLinkedToLocationFilter) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              notLinkedToLocation: this.notLinkedToLocationFilter,
            },
          };
        }

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.filterOrganization,
            },
          };
        }
        if (this.filterOrganizationNull) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: null,
            },
          };
        }
        if (this.filterReseller) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              reseller: this.filterReseller,
            },
          };
        }
        if (this.filterResellerNull) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              reseller: null,
            },
          };
        }
        if (this.filterPermission) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              permission: this.filterPermission,
            },
          };
        }

        await this.$store.dispatch("users/list", params);
        this.usersList = await this.$store.getters["users/list"];
        // this.usersList = {};
        // usersArr.forEach((user) => {
        //   this.usersList[user.id] = user;
        // });
        this.loadingList = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loadingList = false;
      }
    },

    usersChanged(users) {
      this.$emit(
        "usersChanged",
        users.map((item) => {
          return { id: item, type: "users" };
        })
      );
    },
  },

  watch: {
    opened(value) {
      this.$emit("update:open", value);
    },
    open(value) {
      this.opened = value;
    },
    userModel(value) {
      this.$emit("update:user", value);
    },
    user(value) {
      this.userModel = value;
    },
  },
};
</script>

<style lang="scss" scoped></style>
