<template>
  <span class="status" :class="[inspection.status]">
    {{ $t(`INSPECTIONS.INSPECTION_STATUS_${inspection.status}`) }}
  </span>
</template>

<script>
import {
  inspectionStatusesOption,
  INSPECTION_STATUS_APPROVED,
  INSPECTION_STATUS_PENDING,
  INSPECTION_STATUS_IN_PROGRESS,
} from "@/constants/inspections";

export default {
  name: "inspection-status-badge",

  components: {},

  mixins: [],

  props: ["inspection"],

  data() {
    return {
      statusesOptions: inspectionStatusesOption,
    };
  },

  computed: {},

  methods: {},

  mounted() {},

  watch: {},
};
</script>
