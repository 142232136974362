<template>
  <div class="vehicle-vin">
    <el-select
      ref="vinSelector"
      v-model="vehicleModel"
      @change="vehicleChanged"
      autocomplete="new-password"
      :placeholder="vin ? vin : $t('VEHICLES.VIN')"
      :filterable="true"
      :multiple="false"
      :disabled="disabled"
      :remote="!vinScanned"
      :remote-method="getVehiclesDebounced"
      :loading="loading"
      @keyup.native="vinScanned = false"
    >
      <el-option
        v-for="vehicle in vehicles"
        :key="vehicle.vehicle_id"
        :value="vehicle.vehicle_id"
        :label="getLabel({ ...vehicle, type: 'vehicles' })"
      />
    </el-select>
    <vin-scanner @onVinScanned="onVinScanned" />
  </div>
</template>

<script>
import _ from "lodash";
import swal from "sweetalert2";
import { Option, Select } from "element-ui";
import VinScanner from "./VinScanner.vue";

export default {
  components: {
    VinScanner,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    vin: {
      type: String,
      default: null,
      description: "Vin",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "organizationID",
    },
  },

  data() {
    return {
      vehicleModel: this.vin,
      vehicles: {},
      loading: false,
      vinScanned: false,
    };
  },

  setup() {},

  created() {},

  mounted() {},

  methods: {
    getVehiclesDebounced: _.debounce(function (query) {
      this.getVehicles(query);
    }, 300),

    async getVehicles(query = "") {
      if (!query) {
        this.vehicles = [];
        return;
      }
      if (query.length != 17) {
        this.vehicles = [];
        return;
      }
      try {
        this.loading = true;
        const params = {
          vin: query,
          organization: this.filterOrganization,
        };
        await this.$store.dispatch("vehicles/lookupApiByVin", params);
        const vehiclesArr = this.$store.getters["vehicles/apiVehicles"];

        let tempArr = [];

        vehiclesArr.forEach((vehicle) => {
          if (
            !tempArr.find(
              (iterateVehicle) =>
                iterateVehicle.model_year == vehicle.model_year &&
                iterateVehicle.make == vehicle.make &&
                iterateVehicle.model == vehicle.model &&
                iterateVehicle.style == vehicle.style &&
                iterateVehicle.series == vehicle.series &&
                iterateVehicle.fuel_type == vehicle.fuel_type &&
                iterateVehicle.transmission == vehicle.transmission
            )
          ) {
            tempArr.push(vehicle);
          }
        });

        this.vehicles = [...tempArr];
        if (this.vehicles.length == 0) {
          this.$emit("vinChanged", query);
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    vehicleChanged(vehicle_id) {
      this.vinScanned = false;
      const vehicleData = this.vehicles.find(
        (item) => item.vehicle_id == vehicle_id
      );
      this.$emit("vehicleSelected", vehicleData);
    },

    async onVinScanned(vin) {
      console.log(vin);
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      this.vinScanned = true;
      const input = this.$refs.vinSelector.$refs.reference.$refs.input;
      input.value = vin;
      await this.getVehicles(vin);
      if (Object.values(this.vehicles).length == 1) {
        this.vehicleChanged(Object.values(this.vehicles)[0].vehicle_id);
      } else {
        this.$refs.vinSelector.focus();
      }
      swal.close();
    },

    getLabel(object) {
      let fuel_type = object.fuel_type ?? null;

      if (fuel_type) {
        fuel_type = this.$te(`FUEL_TYPES.${fuel_type?.toUpperCase()}`)
          ? this.$t(`FUEL_TYPES.${fuel_type?.toUpperCase()}`)
          : fuel_type;
      }
      let transmission = object.transmission ?? null;

      if (transmission) {
        transmission = this.$te(`TRANSMISSIONS.${transmission?.toUpperCase()}`)
          ? this.$t(`TRANSMISSIONS.${transmission?.toUpperCase()}`)
          : transmission;
      }

      return `${object.model_year} ${object.make ?? ""} ${object.model ?? ""} ${
        object.series ?? ""
      } ${object.style ?? ""} ${object.engine_description ?? ""} ${
        fuel_type ?? ""
      } ${transmission ?? ""}`;
    },
  },

  watch: {
    vin(vin) {
      this.vehicleModel = vin;
    },
  },
};
</script>
