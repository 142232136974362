import { mapGetters } from "vuex";
import {
  ESTIMATE_STATUS_CANCELED,
  ESTIMATE_STATUS_COMPLETED,
  ESTIMATE_STATUS_DRAFT,
  ESTIMATE_STATUS_IN_PROGRESS,
  ESTIMATE_STATUS_IN_PROGRESS_APPROVE,
  ESTIMATE_STATUS_PENDING,
  ESTIMATE_STATUS_PENDING_APPROVE,
  ESTIMATE_TYPES_STATUS_COMPLETED,
  ESTIMATE_TYPES_STATUS_IN_PROGRESS,
  ESTIMATE_TYPES_STATUS_PENDING,
} from "../constants/estimates";

export default {
  computed: {
    ...mapGetters("profile", { me: "me" }),
  },

  methods: {
    canValidEstimate(estimate) {
      return (
        !!estimate &&
        !!estimate.vehicle &&
        this.$currentUserCan(this.$permissions.PERM_VALID_ESTIMATIONS) &&
        estimate.status == ESTIMATE_STATUS_DRAFT
      );
    },

    isPending(estimate) {
      return estimate.status == ESTIMATE_STATUS_PENDING;
    },

    isInProgress(estimate) {
      return estimate.status == ESTIMATE_STATUS_IN_PROGRESS;
    },

    isEstimable(estimate) {
      return (
        !!estimate &&
        [ESTIMATE_STATUS_IN_PROGRESS, ESTIMATE_STATUS_PENDING].includes(
          estimate.status
        )
      );
    },

    estimateIsEnd(estimate) {
      return [ESTIMATE_STATUS_COMPLETED, ESTIMATE_STATUS_CANCELED].includes(
        estimate.status
      );
    },

    componentsIsCompleted(estimate) {
      return estimate.components_status == ESTIMATE_TYPES_STATUS_COMPLETED;
    },

    componentsEstimationIsInProgress(estimate) {
      return [
        ESTIMATE_TYPES_STATUS_IN_PROGRESS,
        ESTIMATE_TYPES_STATUS_COMPLETED,
      ].includes(estimate.components_status);
    },

    amEstimatingComponents(estimate) {
      return (
        this.componentsEstimationIsInProgress(estimate) &&
        estimate.startedComponentsBy.id == this.me.id
      );
    },

    componentsAreEditable(estimate) {
      return (
        this.isEstimable(estimate) &&
        this.$currentUserCan(this.$permissions.PERM_EDIT_ESTIMATIONS_COMPONENTS)
      );
    },

    canEditComponents: function (estimate) {
      return (
        this.componentsAreEditable(estimate) &&
        (!estimate.components_status ||
          estimate.components_status == ESTIMATE_TYPES_STATUS_PENDING ||
          this.amEstimatingComponents(estimate))
      );
    },

    canStartComponentsEstimate(estimate) {
      return (
        this.componentsAreEditable(estimate) &&
        (!estimate.components_status ||
          estimate.components_status == ESTIMATE_TYPES_STATUS_PENDING)
      );
    },

    canEndComponentsEstimate(estimate) {
      return (
        this.componentsAreEditable(estimate) &&
        this.amEstimatingComponents(estimate) &&
        estimate.components_status == ESTIMATE_TYPES_STATUS_IN_PROGRESS
      );
    },

    canTakeOverComponentsEstimate(estimate) {
      return (
        this.componentsEstimationIsInProgress(estimate) &&
        !this.amEstimatingComponents(estimate) &&
        this.componentsAreEditable(estimate) &&
        this.$currentUserCan(this.$permissions.PERM_TAKE_OVER_ESTIMATIONS)
      );
    },

    canChangeComponentsEstimateManager(estimate) {
      return (
        this.$currentUserCan(
          this.$permissions.PERM_CHANGE_ESTIMATIONS_MANAGER
        ) &&
        this.componentsEstimationIsInProgress(estimate) &&
        this.isInProgress(estimate)
      );
    },

    timesEstimationIsInProgress(estimate) {
      return [
        ESTIMATE_TYPES_STATUS_IN_PROGRESS,
        ESTIMATE_TYPES_STATUS_COMPLETED,
      ].includes(estimate.times_status);
    },

    amEstimatingTimes(estimate) {
      return (
        this.timesEstimationIsInProgress(estimate) &&
        estimate.startedTimesBy.id == this.me.id
      );
    },

    timesAreEditable(estimate) {
      return (
        this.isEstimable(estimate) &&
        this.$currentUserCan(this.$permissions.PERM_EDIT_ESTIMATIONS_TIMES)
      );
    },

    canEditTimes: function (estimate) {
      return (
        this.timesAreEditable(estimate) &&
        (!estimate.times_status ||
          estimate.times_status == ESTIMATE_TYPES_STATUS_PENDING ||
          this.amEstimatingTimes(estimate))
      );
    },

    canStartTimesEstimate(estimate) {
      return (
        this.timesAreEditable(estimate) &&
        (!estimate.times_status ||
          estimate.times_status == ESTIMATE_TYPES_STATUS_PENDING)
      );
    },

    canEndTimesEstimate(estimate) {
      return (
        this.timesAreEditable(estimate) &&
        this.amEstimatingTimes(estimate) &&
        estimate.times_status == ESTIMATE_TYPES_STATUS_IN_PROGRESS
      );
    },

    canTakeOverTimesEstimate(estimate) {
      return (
        this.timesEstimationIsInProgress(estimate) &&
        !this.amEstimatingTimes(estimate) &&
        this.timesAreEditable(estimate) &&
        this.$currentUserCan(this.$permissions.PERM_TAKE_OVER_ESTIMATIONS)
      );
    },

    canChangeTimesEstimateManager(estimate) {
      return (
        this.$currentUserCan(
          this.$permissions.PERM_CHANGE_ESTIMATIONS_MANAGER
        ) &&
        this.timesEstimationIsInProgress(estimate) &&
        this.isInProgress(estimate)
      );
    },

    amApprovingEstimate(estimate) {
      return (
        estimate.status == ESTIMATE_STATUS_IN_PROGRESS_APPROVE &&
        estimate.startedApproveBy.id == this.me.id
      );
    },

    isReviewing(estimate) {
      return (
        estimate.status == ESTIMATE_STATUS_IN_PROGRESS &&
        estimate.components_status == ESTIMATE_TYPES_STATUS_COMPLETED &&
        estimate.times_status == ESTIMATE_TYPES_STATUS_COMPLETED &&
        !!estimate.reviewed_at
      );
    },

    canApproveEstimate(estimate) {
      return (
        !!estimate &&
        this.$currentUserCan(this.$permissions.PERM_APPROVE_ESTIMATIONS) &&
        (estimate.status == ESTIMATE_STATUS_PENDING_APPROVE ||
          this.amApprovingEstimate(estimate) ||
          (this.isReviewing(estimate) &&
            estimate.startedApproveBy.id == this.me.id))
      );
    },

    canStartApproveEstimate(estimate) {
      return (
        !!estimate &&
        this.$currentUserCan(this.$permissions.PERM_APPROVE_ESTIMATIONS) &&
        estimate.status == ESTIMATE_STATUS_PENDING_APPROVE
      );
    },

    canEndApproveEstimate(estimate) {
      return (
        !!estimate &&
        this.$currentUserCan(this.$permissions.PERM_APPROVE_ESTIMATIONS) &&
        (this.amApprovingEstimate(estimate) ||
          (this.isReviewing(estimate) &&
            estimate.startedApproveBy.id == this.me.id))
      );
    },

    canEditEstimate(estimate) {
      return (
        !!estimate &&
        this.$currentUserCan(this.$permissions.PERM_EDIT_ESTIMATIONS) &&
        !this.estimateIsEnd(estimate)
      );
    },

    canDeleteEstimate(estimate) {
      return (
        !!estimate &&
        this.$currentUserCan(this.$permissions.PERM_DELETE_ESTIMATIONS) &&
        estimate.status == ESTIMATE_STATUS_DRAFT
      );
    },

    canCancelEstimate(estimate) {
      return (
        !!estimate &&
        this.$currentUserCan(this.$permissions.PERM_CANCEL_ESTIMATIONS) &&
        !this.estimateIsEnd(estimate)
      );
    },

    canViewEstimate(estimate) {
      return (
        this.$idExist(estimate?.id) &&
        this.$currentUserCan(this.$permissions.PERM_VIEW_ESTIMATIONS) &&
        estimate.status != ESTIMATE_STATUS_DRAFT
      );
    },
  },
};
