<template>
  <span class="status" :class="[statusClass]">
    <slot class="flqkre">
      {{ $t(`REPAIRS.REPAIR_STATUS_${status}`) }}
    </slot>
  </span>
</template>

<script>
import { repairStatusesOption } from "@/constants/repairs";
import {
  REPAIR_STATUS_IN_PROGRESS_COMPONENTS,
  REPAIR_STATUS_IN_PROGRESS_TIMES,
  REPAIR_STATUS_PENDING_COMPONENTS,
  REPAIR_STATUS_PENDING_TIMES,
  REPAIR_STATUS_IN_PROGRESS_APPROVE,
  REPAIR_STATUS_PENDING_APPROVE,
  REPAIR_STATUS_PENDING,
  REPAIR_STATUS_IN_PROGRESS,
} from "@/constants/estimates";

export default {
  name: "repair-status-badge",

  components: {},

  mixins: [],

  props: ["repair", "status"],

  data() {
    return {
      statusesOptions: repairStatusesOption,
    };
  },

  computed: {
    statusClass() {
      let status = this.status;
      switch (this.status) {
        case REPAIR_STATUS_PENDING_COMPONENTS:
          status = REPAIR_STATUS_PENDING;
          break;
        case REPAIR_STATUS_PENDING_TIMES:
          status = REPAIR_STATUS_PENDING;
          break;
        case REPAIR_STATUS_PENDING_APPROVE:
          status = REPAIR_STATUS_PENDING;
          break;
        case REPAIR_STATUS_PENDING:
          status = REPAIR_STATUS_PENDING;
          break;
        case REPAIR_STATUS_IN_PROGRESS_COMPONENTS:
          status = REPAIR_STATUS_IN_PROGRESS;
          break;
        case REPAIR_STATUS_IN_PROGRESS_TIMES:
          status = REPAIR_STATUS_IN_PROGRESS;
          break;
        case REPAIR_STATUS_IN_PROGRESS:
          status = REPAIR_STATUS_IN_PROGRESS;
          break;
        case REPAIR_STATUS_IN_PROGRESS_APPROVE:
          status = REPAIR_STATUS_IN_PROGRESS;
          break;
        default:
          break;
      }
      return status;
    },
  },

  methods: {},

  mounted() {},

  watch: {},
};
</script>
