<template>
  <div>
    <div class="container-fluid">
      <vehicle-view-global :vehicle="estimate.vehicle">
        <template #header> <span></span> </template>
      </vehicle-view-global>
      <!-- <estimate-form-vehicle-step
        :estimate-data.sync="estimateData"
        :form-errors="formErrors"
        :disabled="!canEditEstimate(estimateData)"
        disabled-estimate-form
        :hidden-estimate-form="true"
      />
      <div class="add-inspection-steps-content">
        <div class="steps-content-footer">
          <div class="mr-auto align-content-center">
            <Button
              class="next"
              @click="handleSubmit"
              :disabled="!canEditEstimate(estimateData)"
              :class="{ 'opacity-5 cursor-not-allowed': !canEditEstimate }"
            >
              <i
                class="fas fa-spinner fa-spin mr-1"
                v-if="isLoading || loading"
              />
              {{ $t("COMMON.UPDATE") }}
            </Button>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import EstimateFormVehicleStep from "./EstimateFormVehicleStep.vue";
import estimatePermissionsMixin from "@/mixins/estimate-permissions-mixin";
import { isEqual } from "lodash";
import swal from "sweetalert2";
import VehicleRefStandardized from "@/components/VehicleRefStandardized.vue";
import VehicleViewGlobal from "@/views/Pages/VehiclesModule/VehicleManagement/partials/VehicleViewGlobal.vue";

export default {
  layout: "DashboardLayout",

  components: {
    EstimateFormVehicleStep,
    VehicleViewGlobal,
    VehicleRefStandardized,
  },

  mixins: [alertLeave, estimatePermissionsMixin],

  props: ["estimate", "formErrors", "loading"],

  data() {
    const estimateData = cloneDeep(this.estimate);
    const errors = cloneDeep(this.formErrors);
    const isLoading = this.loading ?? false;
    return {
      estimateData,
      isLoading,
      errors,
    };
  },

  created() {},

  computed: {},

  methods: {
    async handleSubmit() {
      if (!this.canEditEstimate(this.estimateData)) {
        return;
      }

      this.errors = null;
      this.isLoading = true;
      swal.showLoading();

      try {
        await this.handleVehicleFormSubmit(this.estimateData.vehicle);

        const estimateData = cloneDeep(this.estimateData);

        delete estimateData.approver;

        delete estimateData.customer;

        this.$emit("onUpdateEstimate", estimateData);
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.errors = error.response?.data?.errors;
      } finally {
        swal.close();
        this.isLoading = false;
      }
    },
    async handleVehicleFormSubmit(vehicleData) {
      vehicleData = { ...this.estimateData.vehicle, ...vehicleData };
      let vehicle = null;
      if (this.$idExist(vehicleData?.id)) {
        delete vehicleData.organization;
        await this.$store.dispatch("vehicles/update", vehicleData);
        vehicle = await this.$store.getters["vehicles/vehicle"];
      } else {
        delete vehicleData.id;
        await this.$store.dispatch("vehicles/add", vehicleData);
        vehicle = await this.$store.getters["vehicles/vehicle"];
      }

      this.estimateData.vehicle = {
        ...this.estimateData.vehicle,
        ...vehicle,
      };
      this.estimateData.organization = {
        ...this.estimateData.organization,
        ...vehicle.organization,
      };
      this.estimateData.allowedLocations = cloneDeep(vehicle.allowedLocations);
    },

    showVehicle() {
      this.$router.push(this.$objectViewRoute(this.estimate.vehicle));
    },
  },

  watch: {
    estimate() {
      if (!isEqual(this.estimateData, this.estimate)) {
        this.estimateData = cloneDeep(this.estimate);
      }
    },
    formErrors() {
      this.errors = cloneDeep(this.formErrors);
    },
  },
};
</script>
