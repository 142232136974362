<template>
  <el-select
    v-model="drivetrainModel"
    @change="drivetrainChanged"
    :placeholder="$t('DRIVETRAIN.DRIVETRAIN')"
    :filterable="filterable"
    :disabled="disabled"
  >
    <el-option :value="null" :label="$t('COMMON.NONE')" />
    <el-option
      v-for="option in drivetrainOptions"
      :key="option"
      :value="option"
      :label="label(option)"
    />
  </el-select>
</template>

<script>
import _ from "lodash";
import { Option, Select } from "element-ui";
import { drivetrainOptions } from "../constants/drivetrain";

export default {
  name: "drivetrain-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    value: {
      type: String | Number | null | undefined,
      default: null,
      description: "Value",
    },
  },

  data() {
    const drivetrainModel = this.value?.toUpperCase() ?? null;
    drivetrainOptions;
    return {
      drivetrainModel,
      drivetrainOptions,
    };
  },

  setup() {},

  created() {},

  computed: {},

  methods: {
    drivetrainChanged(drivetrain) {
      this.$emit("drivetrainChanged", drivetrain?.toLowerCase());
    },
    label(option) {
      return this.$tc(`DRIVETRAIN.DRIVETRAIN_${option.toUpperCase()}`)
        ? this.$t(`DRIVETRAIN.DRIVETRAIN_${option.toUpperCase()}`)
        : option;
    },
  },

  watch: {
    value() {
      this.drivetrainModel = this.value?.toUpperCase();
    },
  },
};
</script>
