export const ESTIMATE_STATUS_DRAFT = "DRAFT";
export const ESTIMATE_STATUS_APPROVED = "APPROVED";
export const ESTIMATE_STATUS_PENDING = "PENDING";
export const ESTIMATE_STATUS_IN_PROGRESS = "IN_PROGRESS";
export const ESTIMATE_STATUS_PENDING_COMPONENTS = "PENDING_COMPONENTS";
export const ESTIMATE_STATUS_IN_PROGRESS_COMPONENTS = "IN_PROGRESS_COMPONENTS";
export const ESTIMATE_STATUS_PENDING_TIMES = "PENDING_TIMES";
export const ESTIMATE_STATUS_IN_PROGRESS_TIMES = "IN_PROGRESS_TIMES";
export const ESTIMATE_STATUS_PENDING_APPROVE = "PENDING_APPROVE";
export const ESTIMATE_STATUS_IN_PROGRESS_APPROVE = "IN_PROGRESS_APPROVE";
export const ESTIMATE_STATUS_ASSIGNED = "ASSIGNED";
export const ESTIMATE_STATUS_COMPLETED = "COMPLETED";
export const ESTIMATE_STATUS_CANCELED = "CANCELED";
export const ESTIMATE_STATUS_REVIEWED = "REVIEWED";
export const ESTIMATE_STATUS_DENIED = "DENIED";

export const ESTIMATE_TYPES_STATUS_PENDING = "PENDING";
export const ESTIMATE_TYPES_STATUS_IN_PROGRESS = "IN_PROGRESS";
export const ESTIMATE_TYPES_STATUS_COMPLETED = "COMPLETED";

export const ESTIMATE_ACTION_TO_REPLACE = "TO_REPLACE";
export const ESTIMATE_ACTION_TO_REPAIR = "TO_REPAIR";
export const ESTIMATE_ACTION_TO_VERIFY = "TO_VERIFY";
export const ESTIMATE_ACTION_TO_SCHEDULE = "TO_SCHEDULE";
export const ESTIMATE_ACTION_TO_MONITOR = "TO_MONITOR";
export const ESTIMATE_ACTION_NO_ACTION = "NO_ACTION";
export const ESTIMATE_INCLUDES = [
  "organization",
  "customer",
  "creator",
  "vehicle",
  "inspection",
  "endedApproveBy",
  "completedBy",
  "reviewedBy",
  "deniedBy",
  "allowedLocations",
  // "evaluationItems",
];

export const estimateStatusesOption = [
  ESTIMATE_STATUS_DRAFT,
  ESTIMATE_STATUS_PENDING,
  ESTIMATE_STATUS_IN_PROGRESS,
  // ESTIMATE_STATUS_PENDING_TIMES,
  // ESTIMATE_STATUS_IN_PROGRESS_TIMES,
  ESTIMATE_STATUS_PENDING_APPROVE,
  ESTIMATE_STATUS_IN_PROGRESS_APPROVE,
  ESTIMATE_STATUS_COMPLETED,
  ESTIMATE_STATUS_CANCELED,
];

export const estimateTypesStatusesOption = [
  ESTIMATE_TYPES_STATUS_PENDING,
  ESTIMATE_TYPES_STATUS_IN_PROGRESS,
  ESTIMATE_TYPES_STATUS_COMPLETED,
];
