<template>
  <div class="inspection-form">
    <customer-step-form-component
      :object-data.sync="estimateData"
      :form-errors="errors"
      :disabled="!canEditEstimate(estimateData)"
    />
    <div class="opacity-0 p-2">
      <Button @click.prevent="() => {}" />
    </div>
    <div class="add-inspection-steps-content">
      <div class="steps-content-footer">
        <div class="mr-auto align-content-center">
          <Button
            class="next"
            :class="{ 'opacity-5 cursor-not-allowed': !canUpdateCustomer }"
            :disabled="!canUpdateCustomer"
            @click="handleSubmit"
          >
            <i
              class="fas fa-spinner fa-spin mr-1"
              v-if="isLoading || loading"
            />
            {{ $t("COMMON.UPDATE") }}
            <i class="fa-light fa-pen-to-square"></i>
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import alertLeave from "@/mixins/alert-leave-mixin";
import CustomerForm from "../../../CrmModule/CustomerManagement/partials/CustomerForm.vue";
import EstimateFormCustomerStep from "./EstimateFormCustomerStep.vue";
import { cloneDeep } from "lodash";
import { isEqual } from "lodash";
import swal from "sweetalert2";
import {
  CUSTOMER_TYPE_COMPANY,
  CUSTOMER_TYPE_INDIVIDUAL,
} from "@/constants/customers";
import estimatePermissionsMixin from "@/mixins/estimate-permissions-mixin";
import CustomerStepFormComponent from "@/components/CustomerStepFormComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    CustomerForm,
    EstimateFormCustomerStep,
    CustomerStepFormComponent,
  },

  mixins: [alertLeave, estimatePermissionsMixin],

  props: ["estimate", "formErrors", "loading"],

  data() {
    const estimateData = cloneDeep(this.estimate);
    const errors = cloneDeep(this.formErrors);
    const isLoading = this.loading ?? false;
    return {
      errors,
      estimateData,
      isLoading,
    };
  },

  created() {},

  computed: {
    canUpdateCustomer() {
      return (
        this.canEditEstimate(this.estimateData) &&
        (this.$idExist(this.estimateData.customer?.id) ||
          this.$idExist(this.estimateData.approver?.id) ||
          !!this.estimateData.customer?.organization)
      );
    },
  },

  methods: {
    async handleSubmit() {
      if (!this.canEditEstimate(this.estimateData)) {
        return;
      }
      this.errors = null;
      this.isLoading = true;
      swal.showLoading();
      try {
        if (!this.$idExist(this.estimateData.approver?.id)) {
          if (!!this.estimateData.customer?.organization) {
            await this.handleCustomerFormSubmit(this.estimateData.customer);
          }
          this.estimateData.approver = null;
        } else {
          this.estimateData.customer = null;
        }

        const estimateData = cloneDeep(this.estimateData);

        delete estimateData.request;

        this.$emit("onUpdateEstimate", estimateData);
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.errors = error.response?.data?.errors;
        swal.close();
      } finally {
        this.isLoading = false;
      }
    },

    async handleCustomerFormSubmit(customerData) {
      customerData = { ...this.estimateData.customer, ...customerData };

      if (customerData.customer_type === CUSTOMER_TYPE_INDIVIDUAL) {
        customerData.company_name = "N/A";
        customerData.billing_company_name = "N/A";
      } else if (customerData.customer_type === CUSTOMER_TYPE_COMPANY) {
        customerData.firstname = "N/A";
        customerData.lastname = "N/A";
        customerData.billing_firstname = "N/A";
        customerData.billing_lastname = "N/A";
      }

      let customer = null;

      if (this.$idExist(customerData?.id)) {
        await this.$store.dispatch("customers/update", customerData);
      } else {
        delete customerData.id;
        await this.$store.dispatch("customers/add", customerData);
      }
      customer = await this.$store.getters["customers/customer"];

      this.estimateData.customer = {
        ...this.estimateData.customer,
        ...customer,
      };
    },
  },

  watch: {
    estimate() {
      if (!isEqual(this.estimateData, this.estimate)) {
        this.estimateData = cloneDeep(this.estimate);
      }
    },
    formErrors() {
      this.errors = cloneDeep(this.formErrors);
    },
  },
};
</script>
<style>
.opacity-0 {
  opacity: 0 !important;
}
</style>
