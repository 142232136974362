<template>
  <el-select
    v-model="transmissionModel"
    @change="transmissionChanged"
    :placeholder="$t('TRANSMISSIONS.TRANSMISSION')"
    :filterable="filterable"
    :disabled="disabled"
  >
    <el-option :value="null" :label="$t('COMMON.NONE')" />
    <el-option
      v-for="option in transmissionsOptions"
      :key="option"
      :value="option"
      :label="$t(`TRANSMISSIONS.${option}`)"
    />
  </el-select>
</template>

<script>
import _ from "lodash";
import { Option, Select } from "element-ui";
import { transmissionsOptions } from "../constants/transmissions";

export default {
  name: "transmission-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    value: {
      type: String | Number | null | undefined,
      default: null,
      description: "Value",
    },
  },

  data() {
    const transmissionModel = this.value?.toUpperCase() ?? null;
    transmissionsOptions;
    return {
      transmissionModel,
      transmissionsOptions,
    };
  },

  setup() {},

  created() {},

  computed: {},

  methods: {
    transmissionChanged(transmission) {
      this.$emit("transmissionChanged", transmission);
    },
  },

  watch: {
    value() {
      this.transmissionModel = this.value?.toUpperCase();
    },
  },
};
</script>
