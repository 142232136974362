<template>
  <div class="input-group">
    <flat-picker
      :config="config"
      class="form-control datepicker"
      v-model="dateRange"
      placeholder="Dates"
    >
    </flat-picker>
    <div class="input-group-append">
      <i class="fal fa-calendar-alt"></i>
    </div>
  </div>
</template>
<script>
import flatPicker from "vue-flatpickr-component";
import { Select, Option, Button } from "element-ui";
import { French } from "flatpickr/dist/l10n/fr.js";
import { Default } from "flatpickr/dist/l10n/default";

export default {
  name: "date-range-selector",

  components: {
    flatPicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  props: {},

  data() {
    return {
      dateRange: null,
      config: {
        allowInput: true,
        mode: "range",
        locale: this.getLocale(),
        plugins: [],
      },
    };
  },

  setup() {},

  created() {},

  methods: {
    getLocale() {
      let locale = Default;
      if (this.$i18n.locale == "fr") {
        locale = French;
      }
      locale.rangeSeparator = " - ";
      return locale;
    },
  },

  watch: {
    dateRange() {
      if (this.dateRange != null) {
        const dateParts = this.dateRange.split(" - ");
        if (dateParts.length == 2) {
          this.$emit("onDateRangeChanged", dateParts);
        }
      }
    },
  },
};
</script>
<style></style>
