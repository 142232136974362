export default {
  type: "vehicles",
  sku: null,
  vin: null,
  make: null,
  model: null,
  series: null,
  model_year: 0,
  style: null,
  engine_description: null,
  fuel_type: null,
  transmission: null,
  drivetrain: null,
  class_code: null,
  class_name: null,
  gallery: [],
  odometer: 0,
  odometer_unit: "km",
  registration_number: null,
  color: null,
  relationshipNames: [
    "organization",
    "allowedLocations",
    "customer",
    "mecanic",
  ],
  organization: {
    type: "organizations",
    id: null,
  },
  customer: {
    type: "customers",
    id: null,
  },
  mecanic: {
    type: "users",
    id: null,
  },
  allowedLocations: [],
};
