<template>
  <el-select
    v-model="yearModel"
    @change="yearChanged"
    :placeholder="$t('ESTIMATE_VEHICLES.MODEL_YEAR')"
    :filterable="filterable"
    :disabled="disabled"
    :loading="loading"
  >
    <el-option
      v-if="allowNone"
      :value="null"
      :label="$t('ESTIMATE_VEHICLES.MODEL_YEAR')"
    />
    <el-option
      v-for="year in years"
      :key="year.value"
      :value="year.value"
      :label="year.label"
    >
    </el-option>
  </el-select>
</template>

<script>
import _ from "lodash";
import { Option, Select } from "element-ui";

export default {
  name: "year-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    value: {
      type: String | Number | null | undefined,
      default: null,
      description: "Value",
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
  },

  data() {
    const yearModel = parseInt(this.value);
    return {
      yearModel,
      years: [],
    };
  },

  setup() {},

  created() {
    this.getYears();
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getYears();
    }, 1000),

    async getYears() {
      try {
        this.loading = true;
        let params = {
          sort: "name",
          filter: {},
        };

        await this.$store.dispatch("estimateVehicleYear/list", params);
        const yearsArr = await this.$store.getters["estimateVehicleYear/list"];
        this.years = [];
        let tempArr = [];
        yearsArr.forEach((year) => {
          if (!tempArr.includes(year.year)) {
            tempArr.push(year.year);
            this.years.push({
              value: year.year,
              label: year.year,
            });
          }
        });
        if (!tempArr.includes(this.yearModel)) {
          this.yearModel = "";
          this.yearChanged("");
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    yearChanged(year) {
      this.$emit("yearChanged", year);
    },
  },

  watch: {
    value() {
      this.yearModel = parseInt(this.value);
    },
    yearModel() {
      if (isNaN(this.yearModel)) {
        this.yearModel = null;
      }
    },
  },
};
</script>
