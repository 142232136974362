<template>
  <div class="validation">
    <div class="validation-photo overflow-hidden">
      <img
        v-if="estimate.vehicle?.gallery?.length > 0"
        :src="estimate.vehicle?.gallery[0]"
        class="avatar rounded-circle mr-3"
      />
      <span
        class="default-icon d-flex h-100 w-100 justify-content-center align-items-center bg-lighter"
        v-else
      >
        <i class="far fa-camera fa-3x"></i>
      </span>
    </div>
    <estimate-view-global :estimate="estimate" :show-buttons="false" />
  </div>
</template>

<script>
import EstimateViewGlobal from "./EstimateViewGlobal.vue";

export default {
  name: "estimate-form-validation-step",

  components: { EstimateViewGlobal },

  mixins: [],

  props: {
    estimateData: { type: Object },
  },

  data() {
    const estimate = { ...this.estimateData };
    return {
      estimate,
    };
  },

  watch: {
    estimateData: {
      handler: function (value) {
        this.estimate = { ...this.estimate, ...value };
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
