<template>
  <div>
    <div class="customer-form-title">
      <h3>{{ $t("CUSTOMERS.CUSTOMER_INFORMATION") }}</h3>
    </div>
    <div class="customer-form-divider"></div>
    <p class="d-flex gap-4">
      <base-radio
        v-model="customerType"
        :disabled="disabled"
        :value="INTERNAL"
        :name="INTERNAL"
        inline
      >
        {{ $t("COMMON.INTERNAL") }}
      </base-radio>
      <base-radio
        v-model="customerType"
        :disabled="disabled"
        :name="EXISTING_CUSTOMER"
        :value="EXISTING_CUSTOMER"
        inline
      >
        {{ $t("CUSTOMERS.EXISTING_CUSTOMER") }}
      </base-radio>
      <base-radio
        v-model="customerType"
        :value="NEW_CUSTOMER"
        :name="NEW_CUSTOMER"
        :disabled="disabled"
        inline
      >
        {{ $t("CUSTOMERS.NEW_CUSTOMER") }}
      </base-radio>
    </p>
    <div class="select-customer" v-if="customerType == EXISTING_CUSTOMER">
      <!-- <div class="customer-form-title">
        <h3>{{ $t("CUSTOMERS.ADD_EXISTING_CUSTOMER") }}</h3>
      </div> -->
      <div>
        <base-input
          :label="`${$t('CUSTOMERS.SELECT_CUSTOMER')}`"
          :placeholder="$t('CUSTOMERS.SELECT_CUSTOMER')"
        >
          <customer-selector
            :customer="estimate.customer?.id"
            :disabled="disabled"
            :filterOrganization="estimate.organization?.id ?? null"
            :filterable="true"
            :showAll="false"
            @customerChanged="getCustomer"
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.customer" />
      </div>
      <div class="px-3">
        <customer-form
          :customerData="estimate.customer"
          :disable="
            disabled || !$currentUserCan($permissions.PERM_CREATE_CUSTOMERS)
          "
          :formErrors="formErrorsValue"
          :loading="loading"
          @formChanged="customerChanged"
        >
          <template #customer-form-title>
            <span>{{ $t("CUSTOMERS.CUSTOMER_INFORMATION") }}</span>
          </template>
          <template #submit-button><span></span></template>
        </customer-form>
      </div>
    </div>
    <div class="select-customer" v-if="customerType == INTERNAL">
      <!-- <div class="customer-form-title">
        <h3>{{ $t("COMMON.ADD_INTERNAL") }}</h3>
      </div> -->
      <base-input
        :label="`${$t('INSPECTIONS.SELECT_APPROVER')}`"
        :placeholder="$t('INSPECTIONS.SELECT_APPROVER')"
      >
        <user-selector
          :user="estimate.approver?.id"
          :disabled="disabled"
          :filterOrganization="estimate.organization?.id ?? null"
          :filterable="true"
          :showAll="false"
          @userChanged="getApprover"
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.customer" />
    </div>
    <customer-form
      :customerData="estimate.customer"
      :disable="
        disabled || !$currentUserCan($permissions.PERM_CREATE_CUSTOMERS)
      "
      :formErrors="formErrorsValue"
      :loading="loading"
      @formChanged="customerChanged"
      v-if="customerType == NEW_CUSTOMER"
    >
      <template #customer-form-title><span></span></template>
      <template #submit-button><span></span></template>
    </customer-form>
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import defaultCustomer from "../../../CrmModule/CustomerManagement/defaultCustomer";
import { cloneDeep } from "lodash";
import CustomerSelector from "@/components/CustomerSelector.vue";
import UserSelector from "@/components/UserSelector.vue";
import CustomerForm from "../../../CrmModule/CustomerManagement/partials/CustomerForm.vue";
import requestErrorMixin from "@/mixins/request-error-mixin";

export default {
  name: "estimate-form-customer-step",

  components: {
    ValidationError,
    CustomerSelector,
    CustomerForm,
    UserSelector,
  },

  mixins: [formMixin, requestErrorMixin],

  props: {
    estimateData: { type: Object },
    formErrors: {
      type: [Object, Array],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    let customer = cloneDeep(this.estimateData.customer);

    customer.organization = this.estimateData.organization;

    let approver = cloneDeep(this.estimateData.approver);

    if (!customer) {
      customer = {
        id: null,
        type: "customers",
      };
    }

    if (!this.$idExist(approver?.id)) {
      approver = null;
    }

    const estimate = {
      ...this.estimateData,
      customer,
      approver,
    };

    const estimateBackup = cloneDeep(estimate);

    const formErrorsValue = cloneDeep(this.formErrors);
    return {
      estimate,
      loading: false,
      formErrorsValue,
      estimateBackup,
      customerType: "EXISTING_CUSTOMER",
      EXISTING_CUSTOMER: "EXISTING_CUSTOMER",
      INTERNAL: "INTERNAL",
      NEW_CUSTOMER: "NEW_CUSTOMER",
    };
  },

  computed: {},

  created() {
    this.setCustomerType();
  },

  methods: {
    setCustomerType() {
      if (
        !this.$idExist(this.estimate.customer?.id) &&
        this.$idExist(this.estimate.approver?.id)
      ) {
        this.customerType = this.INTERNAL;
      } else {
        this.customerType = this.EXISTING_CUSTOMER;
      }
    },

    customerChanged(customerData) {
      this.estimate.customer = {
        ...this.estimate.customer,
        ...customerData,
      };

      if (this.$idExist(customerData?.id)) {
        this.estimateBackup.customer = {
          ...this.estimateBackup.customer,
          ...customerData,
        };
      }
    },

    async getCustomer(customerId, customer) {
      if (!customerId) {
        this.estimate.customer = {
          id: null,
          type: "customers",
        };
      } else {
        this.estimate.customer = customer;
      }
    },

    async getApprover(approverId, approver) {
      if (!approverId) {
        this.estimate.approver = {
          id: null,
          type: "users",
        };
      } else {
        this.estimate.approver = approver;
      }
    },
  },

  watch: {
    estimate: {
      handler: function () {
        this.$emit("update:estimateData", this.estimate);
      },
      deep: true,
    },
    estimateData: {
      handler: function (value) {
        if (_.isEqual(value, this.estimate)) return;

        this.estimate = { ...this.estimate, ...value };
      },
      deep: true,
    },
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
      this.formErrorsValue = cloneDeep(errors);
    },
    customerType() {
      switch (this.customerType) {
        case this.NEW_CUSTOMER:
          let customer = {
            id: null,
            ...cloneDeep(defaultCustomer),
            organization: { ...this.estimateBackup.organization },
          };

          this.estimate = { ...this.estimate, customer, approver: null };
          break;
        case this.INTERNAL:
          this.estimate = {
            ...this.estimate,
            customer: null,
            approver: {
              ...this.estimateBackup.approver,
              id: this.estimateBackup.approver?.id ?? null,
              type: "users",
            },
          };
          break;
        case this.EXISTING_CUSTOMER:
          this.estimate = {
            ...this.estimate,
            approver: null,
            customer: {
              ...this.estimateBackup.customer,
              id: this.estimateBackup.customer?.id ?? null,
              type: "customers",
            },
          };
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.gap-4 {
  gap: 1rem /* 16px */;
}
</style>
