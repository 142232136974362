<template>
  <div class="list-table-inner">
    <div class="list-table-inner-header">
      <div class="search">
        <div
          v-if="$currentUserCan($permissions.PERM_CREATE_ESTIMATIONS)"
          class="search-add"
        >
          <base-button class="add" icon size="sm" @click="addEstimate">
            <span class="btn-inner--icon">
              <i class="fa-regular fa-circle-plus"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("ESTIMATES.ADD_ESTIMATE") }}
            </span>
          </base-button>
        </div>
        <div class="search-form">
          <base-input
            v-model="query"
            type="search"
            prepend-icon="fal fa-search"
            :placeholder="$t('COMMON.SEARCH')"
            clearable
          />
          <button
            @click="
              () => {
                showAdvancedFilter = !showAdvancedFilter;
              }
            "
          >
            <i class="far fa-sliders-h"></i>
          </button>
        </div>

        <!--<notification-subscription
          v-if="$currentUserCan($permissions.PERM_VIEW_ESTIMATIONS)"
          :objectType="'estimations'"
          :events="{
            CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
            UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
            DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
          }"
        />-->
      </div>
      <transition name="slide">
        <div class="advanced-search" v-if="showAdvancedFilter">
          <div class="advanced-search-item pagination-select">
            <el-select
              class="select-primary pagination-select"
              v-model="pagination.perPage"
              :placeholder="$t('COMMON.PER_PAGE')"
            >
              <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <div class="advanced-search-item">
            <el-select class="select-primary" v-model="selectedStatus">
              <el-option
                class="select-primary"
                key="0"
                :label="$t('COMMON.STATUS') + '...'"
                :value="null"
              />
              <el-option
                v-for="value in estimateStatusesOption"
                class="select-primary"
                :key="value"
                :label="$t(`ESTIMATES.ESTIMATE_STATUS_${value}`)"
                :value="value"
              />
            </el-select>
          </div>
          <div class="advanced-search-item date">
            <date-range-selector
              @onDateRangeChanged="
                (dates) => {
                  selectedDateRange = dates;
                }
              "
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
          >
            <user-selector
              :placeholder="$t('ESTIMATES.REVIEWED_BY')"
              @userChanged="(userId) => (selectedReviewedBy = userId)"
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
          >
            <user-selector
              :placeholder="$t('ESTIMATES.COMPLETED_BY')"
              @userChanged="(userId) => (selectedCompletedBy = userId)"
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="
              !filterCreator &&
              $currentUserCan($permissions.PERM_VIEW_ANY_USERS)
            "
          >
            <user-selector
              :placeholder="$t('ESTIMATES.CREATOR')"
              @userChanged="(userId) => (selectedCreator = userId)"
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="
              !filterCustomer &&
              $currentUserCan($permissions.PERM_VIEW_ANY_CUSTOMERS)
            "
          >
            <customer-selector
              :disabled="false"
              @customerChanged="(customerId) => (selectedCustomer = customerId)"
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="
              !filterVehicle &&
              $currentUserCan($permissions.PERM_VIEW_ANY_VEHICLES)
            "
          >
            <year-selector
              :filterable="true"
              :value="selectedVehicleModelYear"
              allow-none
              @yearChanged="
                (year) => {
                  selectedVehicleModelYear = year;
                }
              "
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="
              !filterVehicle &&
              $currentUserCan($permissions.PERM_VIEW_ANY_VEHICLES)
            "
          >
            <make-selector
              allow-none
              :year="selectedVehicleModelYear"
              :filterable="true"
              :placeholder="$t('VEHICLES.MAKE')"
              :value="selectedVehicleMake"
              @makeChanged="
                (make) => {
                  selectedVehicleMake = make;
                }
              "
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="
              !filterVehicle &&
              $currentUserCan($permissions.PERM_VIEW_ANY_VEHICLES)
            "
          >
            <model-selector
              allow-none
              :year="selectedVehicleModelYear"
              :filterable="true"
              :make="selectedVehicleMake"
              :value="selectedVehicleModel"
              @modelChanged="
                (model) => {
                  selectedVehicleModel = model;
                }
              "
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="
              !filterVehicle &&
              $currentUserCan($permissions.PERM_VIEW_ANY_VEHICLES)
            "
          >
            <serie-selector
              allow-none
              :year="selectedVehicleModelYear"
              :model="selectedVehicleModel"
              :filterable="true"
              :value="selectedVehicleSeries"
              @serieChanged="
                (serie) => {
                  selectedVehicleSeries = serie;
                }
              "
            />
          </div>
          <div
            v-if="
              !filterOrganization &&
              $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
            "
            class="advanced-search-item"
          >
            <organization-selector
              @organizationChanged="
                (organizationId) => (selectedOrganization = organizationId)
              "
            />
          </div>
          <div
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
            class="advanced-search-item"
          >
            <base-input :placeholder="`${$t('COMMON.LOCATION')}`">
              <location-selector
                @locationChanged="(location) => (selectedLocation = location)"
              />
            </base-input>
          </div>
          <div
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_INSPECTIONS)"
            class="advanced-search-item"
          >
            <base-input :placeholder="`${$t('COMMON.INSPECTION')}`">
              <inspection-selector
                @inspectionChanged="
                  (inspection) => (selectedInspection = inspection)
                "
              />
            </base-input>
          </div>
          <div class="advanced-search-item update">
            <base-button class="add" icon size="sm">
              <span class="btn-inner--icon">
                <i class="far fa-sync-alt"></i>
              </span>
              <span class="btn-inner--text"> {{ $t("COMMON.UPDATE") }} </span>
            </base-button>
          </div>
        </div>
      </transition>
    </div>

    <estimate-table
      :filter-creator="selectedCreator ?? filterCreator"
      :filter-customer="selectedCustomer ?? filterCustomer"
      :filter-organization="selectedOrganization ?? filterOrganization"
      :filter-status="ESTIMATE_STATUS_IN_PROGRESS"
      :filter-vehicle="selectedVehicle ?? filterVehicle"
      :header="$t('COMMON.IN_PROGRESS')"
      :query="query"
      :selected-date-range="selectedDateRange"
      :filter-inspection="selectedInspection ?? filterInspection"
      :selected-location="selectedLocation"
      :selected-reviewed-by="selectedReviewedBy"
      :selected-vehicle-make="selectedVehicleMake"
      :selected-vehicle-model-year="selectedVehicleModelYear"
      :selected-vehicle-model="selectedVehicleModel"
      :selected-vehicle-series="selectedVehicleSeries"
      :show-pagination="false"
      @onAddEstimate="addEstimate"
      @onDeleteEstimate="deleteEstimate"
      @onEditEstimate="editEstimate"
      @onViewEstimate="viewEstimate"
    />

    <estimate-table
      :exclude-status="ESTIMATE_STATUS_IN_PROGRESS"
      :filter-creator="selectedCreator ?? filterCreator"
      :filter-customer="selectedCustomer ?? filterCustomer"
      :filter-organization="selectedOrganization ?? filterOrganization"
      :filter-status="selectedStatus ?? filterStatus"
      :filter-vehicle="selectedVehicle ?? filterVehicle"
      :query="query"
      :selected-completed-by="selectedCompletedBy"
      :selected-date-range="selectedDateRange"
      :filter-inspection="selectedInspection ?? filterInspection"
      :selected-location="selectedLocation"
      :selected-reviewed-by="selectedReviewedBy"
      :selected-vehicle-make="selectedVehicleMake"
      :selected-vehicle-model-year="selectedVehicleModelYear"
      :selected-vehicle-model="selectedVehicleModel"
      :selected-vehicle-series="selectedVehicleSeries"
      @onAddEstimate="addEstimate"
      @onDeleteEstimate="deleteEstimate"
      @onEditEstimate="editEstimate"
      @onViewEstimate="viewEstimate"
      show-tabs
    />
  </div>
</template>
<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import estimatePermissionsMixin from "@/mixins/estimate-permissions-mixin";
import {
  estimateStatusesOption,
  ESTIMATE_STATUS_DRAFT,
  ESTIMATE_INCLUDES,
  ESTIMATE_STATUS_IN_PROGRESS,
} from "@/constants/estimates";
import { BasePagination } from "@/components";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import DateRangeSelector from "@/components/DateRangeSelector.vue";
import CustomerSelector from "@/components/CustomerSelector.vue";
import UserSelector from "@/components/UserSelector.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationSelector from "@/components/LocationSelector.vue";
import EstimateStatusBadge from "./EstimateStatusBadge.vue";
import InspectionSelector from "@/components/InspectionSelector.vue";
import YearSelector from "@/components/YearSelector.vue";
import MakeSelector from "@/components/MakeSelector.vue";
import ModelSelector from "@/components/ModelSelector.vue";
import SerieSelector from "@/components/SerieSelector.vue";
import CopyElement from "@/components/CopyElement.vue";
import EstimateTable from "./EstimateTable.vue";

export default {
  name: "estimate-list-table",

  components: {
    BasePagination,
    NotificationSubscription,
    DateRangeSelector,
    CustomerSelector,
    OrganizationSelector,
    LocationSelector,
    UserSelector,
    InspectionSelector,
    EstimateStatusBadge,
    YearSelector,
    MakeSelector,
    ModelSelector,
    SerieSelector,
    CopyElement,
    EstimateTable,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin, estimatePermissionsMixin],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "organization id",
    },
    filterCustomer: {
      type: String,
      default: null,
      description: "customer id",
    },
    filterStatus: {
      type: String,
      default: null,
      description: "Status",
    },
    filterCreator: {
      type: String,
      default: null,
      description: "Creator",
    },
    filterVehicle: {
      type: String,
      default: null,
      description: "Vehicle id",
    },
    filterInspection: {
      type: String,
      default: null,
      description: "Inspection id",
    },
  },

  data() {
    return {
      query: null,
      showAdvancedFilter: false,
      estimations: [],
      loading: true,
      selectedEstimationStatus: null,
      selectedStatus: null,
      estimateStatusesOption,
      selectedDateRange: null,
      selectedInspection: null,
      selectedCustomer: null,
      selectedLocation: null,
      selectedCreator: null,
      selectedOrganization: null,
      selectedCompletedBy: null,
      selectedVehicle: null,
      selectedReviewedBy: null,
      ESTIMATE_STATUS_DRAFT,
      selectedVehicleModelYear: null,
      selectedVehicleMake: null,
      selectedVehicleModel: null,
      selectedVehicleSeries: null,
      ESTIMATE_STATUS_IN_PROGRESS,
    };
  },

  computed: {},

  methods: {
    async deleteEstimate(estimate) {
      if (!this.canDeleteEstimate(estimate)) {
        return;
      }
      this.$emit("onDeleteEstimate", estimate);
    },

    viewEstimate(estimate) {
      if (this.canEditEstimate(estimate)) {
        this.$emit("onEditEstimate", estimate);
      }
      if (!this.canViewEstimate(estimate)) {
        return;
      }
      this.$emit("onViewEstimate", estimate);
    },

    editEstimate(estimate) {
      if (!this.canEditEstimate(estimate)) {
        return;
      }
      this.$emit("onEditEstimate", estimate);
    },

    addEstimate() {
      this.$emit("onAddEstimate");
    },
  },
};
</script>
