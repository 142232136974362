<template>
  <div class="resume-header">
    <div class="resume-header-image">
      <img :src="image" alt="logo" class="object-cover" v-if="image" />
      <img class="default" v-else src="/img/add-image.svg" />
    </div>
    <div class="resume-header-text">
      <h3>{{ title }}</h3>
      <slot name="second-title">
        <h4 class="text-muted">{{ secondTitle }}</h4>
      </slot>
      <div class="resume-header-text-button" v-if="showBtn">
        <base-button
          class="btn"
          size="sm"
          type="button"
          @click.prevent="btnAction"
        >
          <span>{{ btnLabel ?? $t("COMMON.UPDATE") }}</span>
          <i class="fal fa-edit"></i>
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "view-object-ref-widget",

  props: {
    image: {
      type: String | null | undefined,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    secondTitle: {
      type: String,
      required: true,
    },
    showBtn: {
      type: Boolean,
      default: false,
    },
    btnLabel: {
      type: String | null | undefined,
      default: null,
    },
    btnAction: {
      type: Function,
      default: "#",
    },
  },

  computed: {},
};
</script>

<style lang="scss" scoped>
/* Resume style */
.resume-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  &-image {
    width: 125px;
    height: 125px;
    border-radius: 50%;
    background-color: #f2f2f2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
      &.default {
        width: 42px;
        height: auto;
        border-radius: 0;
      }
    }
  }
  &-text {
    width: auto;
    padding: 0 0 0 23px;
    display: flex;
    flex-direction: column;
    h3 {
      font-size: 20px;
      line-height: 24px;
      font-family: "Garet Variable", sans-serif;
      margin: 0;
    }
    span {
      font-size: 14px;
      line-height: 20px;
      display: flex;
      flex-direction: row;
      margin: 2px 0 0 0;
      color: #000;
    }
    &-button {
      width: auto;
      display: flex;
      flex-direction: row;
      margin: 14px 0 0 0;
      button {
        width: auto;
        min-height: 40px;
        padding: 0 20px;
        border-radius: 8px;
        background-color: var(--primary-color);
        display: flex;
        flex-direction: row;
        align-items: center;
        span {
          font-family: "Garet Variable", sans-serif;
          font-size: 14px;
          line-height: 20px;
          margin: 0;
          color: #000;
        }
        i {
          margin: 0 0 0 12px;
          font-size: 15px;
          padding: 0;
          color: #000;
        }
      }
    }
  }
}
</style>
