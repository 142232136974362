export const DRIVETRAIN_AWD = "AWD";
export const DRIVETRAIN_4WD = "4WD";
export const DRIVETRAIN_FWD = "FWD";
export const DRIVETRAIN_RWD = "RWD";

export const drivetrainOptions = [
  DRIVETRAIN_AWD,
  DRIVETRAIN_4WD,
  DRIVETRAIN_FWD,
  DRIVETRAIN_RWD,
];
