<template>
  <el-select
    v-model="modelModel"
    @change="modelChanged"
    :placeholder="$t('ESTIMATE_VEHICLES.MODEL')"
    :filterable="filterable"
    :disabled="disabled"
    :loading="loading"
  >
    <el-option
      v-if="allowNone"
      :value="null"
      :label="$t('ESTIMATE_VEHICLES.MODEL')"
    />
    <el-option
      v-for="model in models"
      :key="model.value"
      :value="model.value"
      :label="model.label"
    >
    </el-option>
  </el-select>
</template>

<script>
import _ from "lodash";
import { Option, Select } from "element-ui";

export default {
  name: "model-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    year: {
      type: Number | String | null,
      default: null,
      description: "Year",
    },
    make: {
      type: String | null,
      default: null,
      description: "Make",
    },
    value: {
      type: String | Number | null | undefined,
      default: "",
      description: "Value",
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
  },

  data() {
    const modelModel = this.value?.toLowerCase();
    return {
      modelModel,
      models: [],
    };
  },

  setup() {},

  created() {
    this.getModels();
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getModels();
    }, 300),

    async getModels() {
      try {
        this.loading = true;
        let params = {
          sort: "name",
          filter: {},
        };
        if (this.year) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              year: this.year,
            },
          };
        }

        if (this.make) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              vmake: this.make,
            },
          };
        }

        await this.$store.dispatch("estimateVehicleModel/list", params);
        const modelsArr = await this.$store.getters[
          "estimateVehicleModel/list"
        ];
        this.models = [];
        let tempArr = [];
        modelsArr.forEach((model) => {
          if (!tempArr.includes(model.name.toLowerCase())) {
            tempArr.push(model.name.toLowerCase());
            this.models.push({
              value: model.name.toLowerCase(),
              label: model.name,
            });
          }
        });
        if (!tempArr.includes(this.modelModel?.toLowerCase())) {
          this.modelModel = "";
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    modelChanged(model) {
      this.$emit("modelChanged", model);
    },
  },

  watch: {
    year: {
      handler: "getListDebounced",
      immediate: true,
    },
    make: {
      handler: "getListDebounced",
      immediate: true,
    },
    value() {
      this.modelModel = this.value?.toLowerCase();
    },
  },
};
</script>
