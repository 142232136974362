<template>
  <view-object-ref-widget
    :title="$objectDenomination(vehicle)"
    :second-title="vehicle.vin"
    :image="vehicle.gallery[0] ?? null"
    :show-btn="$currentUserCan($permissions.PERM_EDIT_VEHICLES)"
    :btnAction="update"
  >
    <template #second-title>
      <ul>
        <li>161 345 km</li>
        <li class="line">|</li>
        <li><copy-element :element="vehicle.vin" /></li>
      </ul>
    </template>
  </view-object-ref-widget>
</template>

<script>
import CopyElement from "./CopyElement.vue";
import ViewObjectRefWidget from "./ViewObjectRefWidget.vue";
import { QUERY_ACTIONS_EDIT } from "@/constants/common";

export default {
  name: "vehicle-ref-standardized",

  components: { CopyElement, ViewObjectRefWidget },

  props: ["vehicle"],

  data() {
    return {
      QUERY_ACTIONS_EDIT,
    };
  },

  methods: {
    update() {
      this.$emit("onEditVehicle");
      if (this.$route.name != "List Vehicles") {
        this.$router.push({
          name: "List Vehicles",
          query: { id: this.vehicle.id, action: QUERY_ACTIONS_EDIT },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
