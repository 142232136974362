export const VEHICLE_A = "A";
export const VEHICLE_C = "C";
export const VEHICLE_E = "E";
export const VEHICLE_F = "F";
export const VEHICLE_I = "I";
export const VEHICLE_K = "K";
export const VEHICLE_N = "N";
export const VEHICLE_P = "P";
export const VEHICLE_R = "R";
export const VEHICLE_T = "T";
export const VEHICLE_V = "V";
export const VEHICLE_W = "W";
export const VEHICLE_X = "X";

export const vehicleClassCodeOptions = [
  VEHICLE_A,
  VEHICLE_C,
  VEHICLE_E,
  VEHICLE_F,
  VEHICLE_I,
  VEHICLE_K,
  VEHICLE_N,
  VEHICLE_P,
  VEHICLE_R,
  VEHICLE_T,
  VEHICLE_V,
  VEHICLE_W,
  VEHICLE_X,
];

export const VEHICLE_LONG_A = "A";
export const VEHICLE_LONG_B = "B";
export const VEHICLE_LONG_C = "C";
export const VEHICLE_LONG_E = "E";
export const VEHICLE_LONG_F = "F";
export const VEHICLE_LONG_G = "G";
export const VEHICLE_LONG_H = "H";
export const VEHICLE_LONG_I = "I";
export const VEHICLE_LONG_J = "J";
export const VEHICLE_LONG_K = "K";
export const VEHICLE_LONG_M = "M";
export const VEHICLE_LONG_N = "N";
export const VEHICLE_LONG_O = "O";
export const VEHICLE_LONG_P = "P";
export const VEHICLE_LONG_Q = "Q";
export const VEHICLE_LONG_R = "R";
export const VEHICLE_LONG_S = "S";
export const VEHICLE_LONG_T = "T";
export const VEHICLE_LONG_U = "U";
export const VEHICLE_LONG_V = "V";
export const VEHICLE_LONG_W = "W";
export const VEHICLE_LONG_X = "X";

export const vehicleLongClassCode = [
  VEHICLE_LONG_A,
  VEHICLE_LONG_B,
  VEHICLE_LONG_C,
  VEHICLE_LONG_E,
  VEHICLE_LONG_F,
  VEHICLE_LONG_G,
  VEHICLE_LONG_H,
  VEHICLE_LONG_I,
  VEHICLE_LONG_J,
  VEHICLE_LONG_K,
  VEHICLE_LONG_M,
  VEHICLE_LONG_N,
  VEHICLE_LONG_O,
  VEHICLE_LONG_P,
  VEHICLE_LONG_Q,
  VEHICLE_LONG_R,
  VEHICLE_LONG_S,
  VEHICLE_LONG_T,
  VEHICLE_LONG_U,
  VEHICLE_LONG_V,
  VEHICLE_LONG_W,
  VEHICLE_LONG_X,
];
