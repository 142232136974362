<template>
  <span class="status" :class="[statusClass]">
    <slot class="flqkre">
      {{ $t(`ESTIMATES.ESTIMATE_STATUS_${status}`) }}
    </slot>
  </span>
</template>

<script>
import { estimateStatusesOption } from "@/constants/estimates";
import {
  ESTIMATE_STATUS_APPROVED,
  ESTIMATE_STATUS_ASSIGNED,
  ESTIMATE_STATUS_IN_PROGRESS_COMPONENTS,
  ESTIMATE_STATUS_IN_PROGRESS_TIMES,
  ESTIMATE_STATUS_PENDING_COMPONENTS,
  ESTIMATE_STATUS_PENDING_TIMES,
  ESTIMATE_STATUS_IN_PROGRESS_APPROVE,
  ESTIMATE_STATUS_PENDING_APPROVE,
  ESTIMATE_STATUS_PENDING,
  ESTIMATE_STATUS_IN_PROGRESS,
  ESTIMATE_TYPES_STATUS_PENDING,
  ESTIMATE_TYPES_STATUS_IN_PROGRESS,
  ESTIMATE_TYPES_STATUS_COMPLETED,
} from "@/constants/estimates";

export default {
  name: "estimate-status-badge",

  components: {},

  mixins: [],

  props: ["estimate", "status"],

  data() {
    return {
      statusesOptions: estimateStatusesOption,
    };
  },

  computed: {
    statusClass() {
      let status = this.status;
      switch (this.status) {
        case ESTIMATE_STATUS_PENDING_COMPONENTS:
          status = ESTIMATE_STATUS_PENDING;
          break;
        case ESTIMATE_STATUS_PENDING_TIMES:
          status = ESTIMATE_STATUS_PENDING;
          break;
        case ESTIMATE_STATUS_PENDING_APPROVE:
          status = ESTIMATE_STATUS_PENDING;
          break;
        case ESTIMATE_STATUS_PENDING:
          status = ESTIMATE_STATUS_PENDING;
          break;
        case ESTIMATE_TYPES_STATUS_PENDING:
          status = ESTIMATE_STATUS_PENDING;
          break;
        case ESTIMATE_STATUS_IN_PROGRESS_COMPONENTS:
          status = ESTIMATE_STATUS_IN_PROGRESS;
          break;
        case ESTIMATE_STATUS_IN_PROGRESS_TIMES:
          status = ESTIMATE_STATUS_IN_PROGRESS;
          break;
        case ESTIMATE_STATUS_IN_PROGRESS:
          status = ESTIMATE_STATUS_IN_PROGRESS;
          break;
        case ESTIMATE_TYPES_STATUS_IN_PROGRESS:
          status = ESTIMATE_STATUS_IN_PROGRESS;
          break;
        case ESTIMATE_STATUS_IN_PROGRESS_APPROVE:
          status = ESTIMATE_STATUS_IN_PROGRESS;
          break;
        default:
          break;
      }
      return status;
    },
  },

  methods: {},

  mounted() {},

  watch: {},
};
</script>
