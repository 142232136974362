import { render, staticRenderFns } from "./CommentsModal.vue?vue&type=template&id=2f680f78&scoped=true"
import script from "./CommentsModal.vue?vue&type=script&lang=js"
export * from "./CommentsModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f680f78",
  null
  
)

export default component.exports