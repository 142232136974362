<template>
  <div class="elite-tabs-wrapper-content">
    <div class="global-header" v-if="viewHeader">
      <slot name="header">
        <h3>
          {{ estimate.code }}
        </h3>
        <div class="buttons" v-if="showButtons">
          <button
            class="delete"
            v-if="canCancelEstimate(estimate)"
            @click="showEstimateCancelModel = true"
          >
            <span> {{ $t("COMMON.CANCEL") }} </span>
            <i class="far fa-trash-alt"></i>
          </button>
          <button
            class="edit"
            v-if="canEditEstimate(estimate)"
            @click="editEstimate"
          >
            <span> {{ $t("COMMON.UPDATE") }} </span>
            <i class="far fa-edit"></i>
          </button>
        </div>
      </slot>
    </div>

    <div class="global-body">
      <dl class="width_1-2">
        <dt>{{ $t("COMMON.CODE") }}</dt>
        <dd>
          <object-link :object="estimate" />
        </dd>
      </dl>

      <dl class="width_1-2 status">
        <dt>{{ $t("COMMON.STATUS") }}</dt>
        <dd>
          <estimate-status-badge
            :status="estimate.status"
            :estimate="estimate"
          />
        </dd>
      </dl>

      <dl class="width_1-2">
        <dt>{{ $t("COMMON.EXCERPT") }}</dt>
        <dd class="col-sm-8" v-html="estimate.excerpt"></dd>
      </dl>

      <dl class="width_1-2 status" v-if="estimate.times_status">
        <dt>{{ $t("ESTIMATES.TIMES_STATUS") }}</dt>
        <dd>
          <estimate-status-badge
            :status="estimate.times_status"
            :estimate="estimate"
          >
            {{
              $tc(`ESTIMATES.ESTIMATE_TYPES_STATUS_${estimate.times_status}`)
                ? $t(`ESTIMATES.ESTIMATE_TYPES_STATUS_${estimate.times_status}`)
                : "-"
            }}
          </estimate-status-badge>
        </dd>
      </dl>

      <dl class="width_1-2 status" v-if="estimate.components_status">
        <dt>{{ $t("ESTIMATES.COMPONENTS_STATUS") }}</dt>
        <dd>
          <estimate-status-badge
            :status="estimate.components_status"
            :estimate="estimate"
          >
            {{
              $tc(
                `ESTIMATES.ESTIMATE_TYPES_STATUS_${estimate.components_status}`
              )
                ? $t(
                    `ESTIMATES.ESTIMATE_TYPES_STATUS_${estimate.components_status}`
                  )
                : "-"
            }}
          </estimate-status-badge>
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          $idExist(estimate.customer?.id) &&
          !!estimate.customer?.customer_type
        "
      >
        <dt>{{ $t("COMMON.CUSTOMER") }}</dt>
        <dd>
          <users :users="[estimate.customer]" />
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          $idExist(estimate.approver?.id) &&
          !!estimate.approver?.email
        "
      >
        <dt>{{ $t("COMMON.APPROVER") }}</dt>
        <dd>
          <object-link :object="estimate.approver" />
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_VEHICLES) &&
          !!estimate.vehicle
        "
      >
        <dt>{{ $t("COMMON.VEHICLE") }}</dt>
        <dd>
          <object-link :object="estimate.vehicle" />
        </dd>
      </dl>

      <dl class="width_1-2">
        <dt>{{ $t("VEHICLES.ODOMETER") }}</dt>
        <dd>
          {{ estimate.odometer ?? estimate.vehicle?.odometer }}
          {{ estimate.vehicle?.odometer_unit }}
        </dd>
      </dl>

      <dl class="width_1-2">
        <dt>{{ $t("COMMON.LOCATIONS") }}</dt>
        <dd>
          <locations :locations="estimate.allowedLocations" />
        </dd>
      </dl>

      <dl class="width_1-2" v-if="!!estimate.created_at">
        <dt>{{ $t("ESTIMATES.CREATED_AT") }}</dt>
        <dd>
          {{ $timeZoneDateFormat(estimate.created_at, "LLLL") }}
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          estimate.creator && $currentUserCan($permissions.PERM_VIEW_ANY_USERS)
        "
      >
        <dt>{{ $t("ESTIMATES.CREATOR") }}</dt>
        <dd>
          <object-link :object="estimate.creator" />
        </dd>
      </dl>

      <dl class="width_1-2" v-if="!!estimate.validated_at">
        <dt>{{ $t("ESTIMATES.VALIDATED_AT") }}</dt>
        <dd>
          {{ $timeZoneDateFormat(estimate.validated_at, "LLLL") }}
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          !!estimate.validatedBy
        "
      >
        <dt>{{ $t("ESTIMATES.VALIDATED_BY") }}</dt>
        <dd>
          <object-link :object="estimate.validatedBy" />
        </dd>
      </dl>

      <dl class="width_1-2" v-if="!!estimate.started_components_at">
        <dt>{{ $t("ESTIMATES.STARTED_COMPONENTS_AT") }}</dt>
        <dd>
          {{ $timeZoneDateFormat(estimate.started_components_at, "LLLL") }}
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          !!estimate.startedComponentsBy
        "
      >
        <dt>{{ $t("ESTIMATES.STARTED_COMPONENTS_BY") }}</dt>
        <dd>
          <object-link :object="estimate.startedComponentsBy" />
        </dd>
      </dl>

      <dl class="width_1-2" v-if="!!estimate.ended_components_at">
        <dt>{{ $t("ESTIMATES.ENDED_COMPONENTS_AT") }}</dt>
        <dd>
          {{ $timeZoneDateFormat(estimate.ended_components_at, "LLLL") }}
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          !!estimate.endedComponentsBy
        "
      >
        <dt>{{ $t("ESTIMATES.ENDED_COMPONENTS_BY") }}</dt>
        <dd>
          <object-link :object="estimate.endedComponentsBy" />
        </dd>
      </dl>

      <dl class="width_1-2" v-if="!!estimate.started_times_at">
        <dt>{{ $t("ESTIMATES.STARTED_TIMES_AT") }}</dt>
        <dd>
          {{ $timeZoneDateFormat(estimate.started_times_at, "LLLL") }}
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          !!estimate.startedTimesBy
        "
      >
        <dt>{{ $t("ESTIMATES.STARTED_TIMES_BY") }}</dt>
        <dd>
          <object-link :object="estimate.startedTimesBy" />
        </dd>
      </dl>

      <dl class="width_1-2" v-if="!!estimate.ended_times_at">
        <dt>{{ $t("ESTIMATES.ENDED_TIMES_AT") }}</dt>
        <dd>
          {{ $timeZoneDateFormat(estimate.ended_times_at, "LLLL") }}
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          !!estimate.endedTimesBy
        "
      >
        <dt>{{ $t("ESTIMATES.ENDED_TIMES_BY") }}</dt>
        <dd>
          <object-link :object="estimate.endedTimesBy" />
        </dd>
      </dl>

      <dl class="width_1-2" v-if="!!estimate.started_approve_at">
        <dt>{{ $t("ESTIMATES.STARTED_APPROVE_AT") }}</dt>
        <dd>
          {{ $timeZoneDateFormat(estimate.started_approve_at, "LLLL") }}
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          !!estimate.startedApproveBy
        "
      >
        <dt>{{ $t("ESTIMATES.STARTED_APPROVE_BY") }}</dt>
        <dd>
          <object-link :object="estimate.startedApproveBy" />
        </dd>
      </dl>

      <dl class="width_1-2" v-if="!!estimate.ended_approve_at">
        <dt>{{ $t("ESTIMATES.ENDED_APPROVE_AT") }}</dt>
        <dd>
          {{ $timeZoneDateFormat(estimate.ended_approve_at, "LLLL") }}
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          !!estimate.endedApproveBy
        "
      >
        <dt>{{ $t("ESTIMATES.ENDED_APPROVE_BY") }}</dt>
        <dd>
          <object-link :object="estimate.endedApproveBy" />
        </dd>
      </dl>

      <dl class="width_1-2" v-if="!!estimate.reviewed_at">
        <dt>{{ $t("ESTIMATES.REVIEWED_AT") }}</dt>
        <dd>
          {{ $timeZoneDateFormat(estimate.reviewed_at, "LLLL") }}
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          !!estimate.reviewedBy
        "
      >
        <dt>{{ $t("ESTIMATES.REVIEWED_BY") }}</dt>
        <dd>
          <object-link :object="estimate.reviewedBy" />
        </dd>
      </dl>

      <dl class="width_1-2" v-if="!!estimate.canceled_at">
        <dt>
          {{ $t("ESTIMATES.CANCELED_AT") }}
        </dt>
        <dd>
          {{ $timeZoneDateFormat(estimate.canceled_at, "LLLL") }}
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          !!estimate.canceledBy
        "
      >
        <dt>
          {{ $t("ESTIMATES.CANCELED_BY") }}
        </dt>
        <dd>
          <object-link :object="estimate.canceledBy" />
        </dd>
      </dl>

      <dl class="width_1-2" v-if="!!estimate.completed_at">
        <dt>
          {{ $t("ESTIMATES.COMPLETED_AT") }}
        </dt>
        <dd>
          {{ $timeZoneDateFormat(estimate.completed_at, "LLLL") }}
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          !!estimate.completedBy
        "
      >
        <dt>
          {{ $t("ESTIMATES.COMPLETED_BY") }}
        </dt>
        <dd>
          <object-link :object="estimate.completedBy" />
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          estimate.organization &&
          $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
        "
      >
        <dt>{{ $t("COMMON.ORGANIZATION") }}</dt>
        <dd>
          <organization :organization="estimate.organization" />
        </dd>
      </dl>
    </div>
    <inspection-confirmation-modal
      :confirm-action="cancelEstimate"
      :confirm-button-text="$t('COMMON.YES_CANCEL')"
      :loading="loading"
      :message="$t('ESTIMATES.CANCEL_THIS_ESTIMATE')"
      :open.sync="showEstimateCancelModel"
      modal-classes="modal-secondary cancel"
    />
  </div>
</template>

<script>
import { ESTIMATE_STATUS_CANCELED } from "@/constants/estimates";

import Organization from "@/components/Organization.vue";
import EstimateStatusBadge from "./EstimateStatusBadge.vue";
import Users from "@/components/Users.vue";
import { QUERY_ACTIONS_VIEW } from "@/constants/common";
import InspectionConfirmationModal from "@/components/InspectionConfirmationModal.vue";
import estimatePermissionsMixin from "@/mixins/estimate-permissions-mixin";

export default {
  name: "estimate-view-global",

  components: {
    Users,
    Organization,
    EstimateStatusBadge,
    InspectionConfirmationModal,
  },

  mixins: [estimatePermissionsMixin],

  props: {
    estimate: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
    viewHeader: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      ESTIMATE_STATUS_CANCELED,
      QUERY_ACTIONS_VIEW,
      showEstimateCancelModel: false,
    };
  },

  computed: {},

  created() {},

  methods: {
    estimateUpdated() {
      this.$emit("estimateUpdated", true);
    },
    cancelEstimate() {
      this.$emit("onCancelEstimate", true);
    },
    editEstimate() {
      this.$emit("onEditEstimate", true);
    },
  },

  mounted() {},

  watch: {
    estimate(estimate) {},
  },
};
</script>
