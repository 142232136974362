<template>
  <transition name="slide-right">
    <div
      v-if="opened"
      class="resizable-wrapper"
      v-bind:class="[opened ? 'show' : 'hide']"
    >
      <div
        class="resizable-wrapper-content"
        v-resizable-modal="'COMMENTS_MODAL'"
      >
        <div class="resizable-wrapper-content-header">
          <div class="resizable-wrapper-content-header-left">
            <base-button
              icon
              size="sm"
              class="back-button"
              @click.prevent="opened = false"
            >
              <i class="fal fa-arrow-left"></i>
            </base-button>
            <h1>
              {{ $t("COMMON.COMMENTS") }}
            </h1>
          </div>
          <div class="resizable-wrapper-content-header-right">
            <button class="expand" id="expand-button">
              <img src="../../../public/img/sm-arrows-expand.svg" alt="icon" />
            </button>
            <button class="close" @click.prevent="opened = false">
              <i class="fal fa-times"></i>
            </button>
          </div>
        </div>
        <div class="resizable-wrapper-content-body">
          <comments-component
            :object="object"
            @onCommentsChanged="onCommentsChanged"
            :canAddComment="canAddComment"
            @close="opened = false"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { Button } from "element-ui";
import CommentsComponent from "@/components/Comments/CommentsComponent.vue";

export default {
  name: "comments-modal",

  components: {
    CommentsComponent,
    [Button.name]: Button,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
    object: {
      type: Object,
      required: true,
      description: "Comments linked object",
    },
    canAddComment: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    const opened = this.open;
    return {
      opened,
    };
  },

  methods: {
    onCommentsChanged(comment) {
      this.$emit("onCommentsChanged", comment);
    },
  },

  watch: {
    opened(value) {
      this.$emit("update:open", value);
    },
    open(value) {
      this.opened = value;
    },
  },
};
</script>

<style lang="scss" scoped></style>
