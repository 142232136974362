<template>
  <modal :show.sync="opened" :modal-classes="modalClasses">
    <template slot="header">
      <div class="header">
        <div class="header-left">
          <i class="fas fa-exclamation-triangle"></i>
          <span>{{ $t("COMMON.IMPORTANT") }}</span>
        </div>
        <div class="header-right">
          <button @click.prevent="opened = false">
            <i class="fal fa-times"></i>
          </button>
        </div>
      </div>
    </template>
    <p :class="messageClasses">{{ message }}</p>
    <template slot="footer">
      <Button
        class="no"
        @click.prevent="opened = false"
        v-if="showCancelButton"
      >
        {{ cancelButtonText ?? $t("COMMON.NO") }}
      </Button>
      <Button class="yes" @click.prevent="confirmAction">
        <i class="fas fa-spinner fa-spin mr-1" v-if="loading"></i>
        {{ confirmButtonText ?? $t("COMMON.YES") }}
      </Button>
    </template>
  </modal>
</template>

<script>
export default {
  name: "inspection-confirmation-modal",

  props: {
    message: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      default: false,
    },
    confirmAction: {
      type: Function,
      default: () => {},
    },
    cancelAction: {
      type: Function,
      default: () => {},
    },
    confirmButtonText: {
      type: String | null | undefined,
      default: null,
    },
    cancelButtonText: {
      type: String | null | undefined,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    modalClasses: {
      type: String,
      default: "modal-secondary",
    },
    showCancelButton: {
      type: Boolean,
      default: true,
    },
    messageClasses: {
      type: String,
      default: "",
    },
  },

  data() {
    const opened = this.open;
    return {
      opened,
    };
  },

  watch: {
    opened(value, oldValue) {
      if (oldValue && !value) {
        this.cancelAction();
      }
      this.$emit("update:open", value);
    },
    open(value) {
      this.opened = value;
    },
  },
};
</script>

<style lang="scss" scoped></style>
