<template>
  <el-select
    v-model="classCodeModel"
    @change="classCodeChanged"
    :placeholder="$t('VEHICLES_CLASSES.CLASS')"
    :filterable="filterable"
    :disabled="disabled"
  >
    <el-option :value="null" :label="$t('COMMON.NONE')" />
    <el-option
      v-for="option in vehicleClassCodeOptions"
      :key="option"
      :value="option"
      :label="label(option)"
    />
  </el-select>
</template>

<script>
import _ from "lodash";
import { Option, Select } from "element-ui";
import { vehicleClassCodeOptions } from "../constants/vehicleClassCode";

export default {
  name: "vehicle-class-code-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    value: {
      type: String | Number | null | undefined,
      default: null,
      description: "Value",
    },
  },

  data() {
    const classCodeModel = this.value?.toUpperCase() ?? null;

    return {
      classCodeModel,
      vehicleClassCodeOptions,
    };
  },

  setup() {},

  created() {},

  computed: {},

  methods: {
    classCodeChanged(classCode) {
      this.$emit("classCodeChanged", classCode?.toLowerCase());
    },
    label(option) {
      return this.$tc(`VEHICLES_CLASSES.VEHICLE_${option.toUpperCase()}`)
        ? this.$t(`VEHICLES_CLASSES.VEHICLE_${option.toUpperCase()}`)
        : option;
    },
  },

  watch: {
    value() {
      this.classCodeModel = this.value?.toUpperCase();
    },
  },
};
</script>
