<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!estimate">
      <span class="loader"></span>
    </span>
    <estimate-form
      v-if="estimate"
      :formErrors="formErrors"
      :estimateData="estimate"
      :loading="loading"
      @formChanged="
        () => {
          alertLeave = true;
          get();
        }
      "
      @onViewEstimate="onViewEstimate"
      @onCancelEstimate="onCancelEstimate"
      @onCloseEstimateModal="onCloseEstimateModal"
    />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import EstimateForm from "../partials/EstimateForm.vue";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { ESTIMATE_STATUS_DRAFT } from "@/constants/estimates";
import estimatePermissionsMixin from "@/mixins/estimate-permissions-mixin";

export default {
  layout: "DashboardLayout",

  components: { EstimateForm },

  mixins: [alertLeave, requestErrorMixin, estimatePermissionsMixin],

  props: {
    estimateId: {
      type: [String, Number],
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      estimate: null,
      formErrors: null,
    };
  },

  computed: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("estimations/get", this.estimateId);
        this.estimate = this.$store.getters["estimations/estimation"];
        this.estimate.vehicle_vin = this.estimate.vehicle.vin;
        if (this.canEditEstimate(this.estimate)) {
          this.$emit("estimateLoaded", this.estimate);
        } else {
          this.$emit("onViewEstimate", this.estimate);
        }
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
      }
    },

    async onViewEstimate(estimate = null) {
      if (!estimate) {
        await this.get();
        estimate = this.estimate;
      }

      this.$emit("onViewEstimate", estimate);
    },

    async onCancelEstimate() {
      await this.get();

      this.$emit("onCancelEstimate", this.estimate);
    },

    onCloseEstimateModal() {
      this.$emit("onCloseEstimateModal");
    },
  },
};
</script>
