<template>
  <div
    class="estimate-comments"
    data-spy="scroll"
    data-target=".scrollspy-target"
    data-offset="0"
  >
    <div
      class="estimate-comments-item"
      v-for="comment in comments"
      :key="comment.id"
      :id="`scrollspy-${comment.id}`"
    >
      <comments-item-card :comment="comment" @onReply="onReply" />
      <!-- <comments-item-card
        v-for="reply in comment.replies"
        :comment="reply"
        @onReply="onReply(comment)"
        class="sub-line"
        hidden-reply
      /> -->
    </div>
    <div
      class="inspection-form-group next-button p-0 pb-4 w-auto justify-content-center border-top-0"
      v-if="canLoadMore"
    >
      <base-button
        class="btn btn-sm elite-submit"
        size="sm"
        type="button"
        @click.prevent="loadMore"
      >
        <span class="text-nowrap">
          {{ $t("COMMON.VIEW_MORE") }}
        </span>
      </base-button>
    </div>
  </div>
</template>

<script>
import CommentsItemCard from "./CommentsItemCard.vue";

export default {
  name: "comments-list",

  components: { CommentsItemCard },

  props: {
    comments: {
      type: Array,
      default: () => [],
    },
    canLoadMore: {
      type: Boolean,
      default: false,
    },
  },

  computed: {},

  created() {},

  methods: {
    onReply(comment) {
      this.$emit("onReply", comment);
    },
    loadMore() {
      this.$emit("onLoadMore");
    },
  },
};
</script>

<style lang="scss" scoped></style>
