<template>
  <div class="w-100">
    <span class="resize-loading" v-if="loading">
      <span class="loader"></span>
    </span>
    <comments-list
      :comments="comments"
      @onReply="onReply"
      :can-load-more="canLoadMore"
      @onLoadMore="onLoadMore"
    />
    <div class="estimate-comment-add" v-if="canAddComment">
      <base-input
        type="textarea"
        :label="$t('COMMON.NEW_COMMENT')"
        :placeholder="$t('COMMON.NEW_COMMENT')"
        :disabled="!canComment"
      >
        <div
          class="bg-lighter mt-2 p-3 border-left-4px text-sm"
          v-if="comment.replyTo"
        >
          <button
            type="button"
            class="close"
            aria-label="Close"
            @click.prevent="removeReply"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {{ comment.replyTo.short_comment }}
        </div>
        <html-editor v-model="comment.comment" :disabled="!canComment" />
      </base-input>
      <div class="estimate-comment-add-buttons">
        <base-button
          type="button"
          class="btn btn-sm add"
          @click.prevent="submitComment"
          :disabled="!commentTextLength || !canComment"
        >
          {{ $t("COMMON.SUBMIT") }}
          <i class="fal fa-arrow-right"></i>
        </base-button>
        <base-button
          @click.prevent="reset"
          type="button"
          class="btn btn-sm cancel"
        >
          {{ $t("COMMON.DELETE") }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import CommentsList from "./partials/CommentsList.vue";
import defaultComment from "./defaultComment";
import { cloneDeep } from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "comments-component",

  components: {
    HtmlEditor,
    CommentsList,
  },

  props: {
    object: {
      type: Object,
      required: true,
      description: "Comments linked object",
    },
    canAddComment: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    let comment = cloneDeep({
      ...defaultComment,
      object: this.object,
      organization: this.object.organization,
    });

    comment = this.$fillUserOrganizationData(comment);

    return {
      comment,
      comments: [],
      loading: false,
      perPage: 5,
    };
  },

  computed: {
    ...mapGetters("comments", { total: "listTotal" }),
    canLoadMore: function () {
      return this.total > this.comments.length;
    },
    commentTextLength: function () {
      return new DOMParser().parseFromString(this.comment.comment, "text/html")
        .body.textContent.length;
    },
    canComment: function () {
      return this.$currentUserCan(this.$permissions.PERM_CREATE_COMMENTS);
    },
  },

  async created() {
    await this.getList();
  },

  methods: {
    async getList() {
      this.loading = true;
      try {
        let params = {
          sort: "-created_at",
          filter: {
            // replyTo: null,
          },
          include: "organization,creator,replyTo,object",
        };

        if (this.$idExist(this.comment.organization?.id)) {
          params = {
            ...params,
            page: {
              size: this.perPage,
            },
            filter: {
              ...params.filter,
              organization: this.comment.organization?.id,
            },
          };
        }
        if (this.object) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              objectType: this.object.type,
              objectId: this.object.id,
            },
          };
        }

        await this.$store.dispatch("comments/list", params);
        this.comments = this.$store.getters["comments/list"];

        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    async submitComment() {
      // this.$emit("onSubmitComment", this.comment);

      this.loading = true;

      try {
        await this.$store.dispatch("comments/add", this.comment);

        await this.getList();

        this.comment = { ...this.comment, comment: "", replyTo: null };

        this.loading = false;

        this.$emit("onCommentsChanged");

        this.$nextTick(function () {
          this.$emit("close");
        });
      } catch (error) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },

    onReply(comment) {
      this.comment = { ...this.comment, replyTo: comment };
    },
    removeReply() {
      this.comment = { ...this.comment, replyTo: null };
    },
    onLoadMore() {
      this.perPage = this.perPage + 5;
    },
    reset() {
      this.comment.comment = "";
      // this.$emit("close");
    },
  },

  watch: {
    async perPage() {
      await this.getList();
    },
  },
};
</script>

<style lang="scss">
.border-left-4px {
  border-left-style: solid !important;
  border-left-width: 4px !important;
}
.border-color-primary {
  border-color: var(--primary-color);
}
</style>
