var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[(!_vm.estimate)?_c('span',{staticClass:"resize-loading"},[_c('span',{staticClass:"loader"})]):_c('div',{staticClass:"elite-tabs-wrapper"},[_c('tabs',{staticClass:"flex-column flex-md-row",attrs:{"fill":"","tabNavWrapperClasses":"nav-wrapper tabs-wrapper-nav","tabNavClasses":"nav elite-tabs","value":_vm.getActiveTab()},on:{"onTabChanged":_vm.tabChanged}},[_c('tab-pane',{attrs:{"title":"global","id":"1","active":false}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"fa-regular fa-list"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.GLOBAL"))+" ")]),_c('div',{staticClass:"tab-pane-inner"}),_c('estimate-view-global',{attrs:{"estimate":_vm.estimate},on:{"onCancelEstimate":_vm.onCancelEstimate,"onEditEstimate":_vm.onEditEstimate}})],1),_c('tab-pane',{attrs:{"title":"images","id":"2","active":false}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"fa-regular fa-image"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.PICTURES"))+" ")]),_c('estimate-view-images',{attrs:{"estimate":_vm.estimate}})],1),(
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_INSPECTIONS) &&
          !!_vm.estimate.inspection
        )?_c('tab-pane',{attrs:{"title":"inspection","id":"3","active":false}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"fa-regular fa-clipboard-check"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.INSPECTION"))+" ")]),_c('div',{staticClass:"tab-pane-inner"}),_c('estimate-view-inspection',{attrs:{"estimate":_vm.estimate}})],1):_vm._e(),(
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_REPAIRS) &&
          !!_vm.estimate.repair
        )?_c('tab-pane',{attrs:{"title":"repair","id":"4","active":false}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"fa-regular fa-wrench"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.REPAIR"))+" ")]),_c('div',{staticClass:"tab-pane-inner"}),_c('estimate-view-repair',{attrs:{"estimate":_vm.estimate}})],1):_vm._e(),(_vm.estimate.status != _vm.ESTIMATE_STATUS_DRAFT)?_c('tab-pane',{attrs:{"active":false,"id":"5","title":"estimate"}},[_c('span',{staticClass:"title",attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"far fa-usd-circle"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.ESTIMATE"))+" ")]),_c('div',{staticClass:"tab-pane-inner"},[_c('estimate-view-estimate',{key:_vm.estimateViewEstimateKey,attrs:{"estimateData":_vm.estimate,"loading":_vm.isLoading,"hourlyRateValue":_vm.hourlyRate},on:{"estimateUpdated":_vm.estimateUpdated,"onCloseEstimateModal":_vm.onCloseEstimateModal,"onCancelEstimate":_vm.onCancelEstimate,"onStartComponentsEstimate":_vm.onStartComponentsEstimate,"onEndComponentsEstimate":_vm.onEndComponentsEstimate,"onStartTimesEstimate":_vm.onStartTimesEstimate,"onEndTimesEstimate":_vm.onEndTimesEstimate,"onStartApproveEstimate":_vm.onStartApproveEstimate,"onEndApproveEstimate":_vm.onEndApproveEstimate,"onUpdateEstimate":_vm.updateEstimate,"onTakeOverComponentsEstimate":_vm.onTakeOverComponentsEstimate,"onTakeOverTimesEstimate":_vm.onTakeOverTimesEstimate}})],1)]):_vm._e(),(
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_VEHICLES) &&
          !!_vm.estimate.vehicle
        )?_c('tab-pane',{attrs:{"title":"vehicle","id":"6","active":false}},[_c('span',{staticClass:"title",attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"fa-regular fa-car"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.VEHICLE"))+" ")]),_c('div',{staticClass:"tab-pane-inner"},[_c('estimate-view-vehicle',{attrs:{"estimate":_vm.estimate},on:{"onUpdateEstimate":_vm.updateEstimate}})],1)]):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_CUSTOMERS))?_c('tab-pane',{attrs:{"title":"client","id":"7","active":false}},[_c('span',{staticClass:"title",attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"fa-regular fa-user"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.CUSTOMER"))+" ")]),_c('estimate-view-customer',{attrs:{"estimate":_vm.estimate},on:{"onUpdateEstimate":_vm.updateEstimate}})],1):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_FILES))?_c('tab-pane',{attrs:{"title":"files","id":"8","active":false}},[_c('span',{staticClass:"title",attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"far fa-file-alt"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.FILES"))+" ")]),_c('div',{staticClass:"elite-tabs-wrapper-content"},[_c('list-file-component',{attrs:{"object":_vm.estimate}})],1)]):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOGS))?_c('tab-pane',{attrs:{"id":"999","title":"logs"}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"fa fa-history"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.LOGS"))+" ")]),_c('estimate-view-logs',{attrs:{"estimate":_vm.estimate}})],1):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }